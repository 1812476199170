import { NavDropdown } from "react-bootstrap";
const Dropdown = () => {
    return (
        
        <NavDropdown title="دسته بندی کالاها" className="fw-bold " id=" bi bi-list">
           <NavDropdown.Item href="#action3">محصولات زنانه</NavDropdown.Item>
           <NavDropdown.Item href="#action4">
              محصولات مردانه
           </NavDropdown.Item>
           <NavDropdown.Divider />
           <NavDropdown.Item href="#action5">
               محصولات بچه گانه
           </NavDropdown.Item>
       </NavDropdown>
       
    )
}
export default Dropdown; 