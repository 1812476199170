import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import {HelmetProvider } from 'react-helmet-async';
import {theme} from '../Them/Them';
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
const MainLayout=({children}) => {
    return(
        <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <HelmetProvider>
            {children}
        </HelmetProvider>
      </ThemeProvider>

    </CacheProvider>
    )
}

export default MainLayout;