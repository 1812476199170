import { Typography } from '@mui/material';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from '../../src/Components/Navbar.item.dropdown';
function Header() {
  const Navlinks=[
    {name:'درباره ی من',href:'#about me',id:'0'},
    {name:'تخصص ها',href:'#Specialties',id:'1'},
    {name:'مدارک من',href:'#my documents',id:'2'},
    {name:'خدمات',href:'#Services',id:'3'},
    {name:'تجربه های من',href:'#my experiences',id:'4'},
    {name:'نوشته ها',href:'#Posts',id:'5'},
    {name:'ارتباط با من',href:'#contact with me',id:'6'}
  ]
  return (
    <>
     <div className='justify-content-center align-content-center d-flex  sticky  z-3'>
     <Navbar expand="lg" className="class-header w-75 mt-4  ">
        <Container fluid  className='rounded-4 sticky-c'>
          <Navbar.Brand href="#logo" className='p-0'><Typography variant='h5'>کیف و کفش آرمان</Typography></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav ">
            <Nav className="ms-auto ">
            <Dropdown/>
              {Navlinks.map((Navlinks,index)=>(
                <Nav.Link href={Navlinks.href} className='navbar-font fw-bold' id={Navlinks.id}>{Navlinks.name}</Nav.Link>
              ))}
             
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
     </div>
    </>
  );
}

export default Header;