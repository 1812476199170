import { Typography } from "@mui/material";
import { Container } from "react-bootstrap";
const footer = () => {
    return (
        <>
            <Container fluid className=" rounded-top-5  footer-s fborder">
                <div className="row">
                    <div className="col-4" >

                    </div>
                    <div className="col-4">
                    </div>
                    <div className="col-4 " >
                    <button className="btn btn-outline-danger rounded-end-0"><i class="bi bi-instagram"></i></button>
                         <button className="btn btn-outline-danger rounded-0"><i class="bi bi-youtube"></i></button>
                         <button className="btn btn-outline-primary rounded-"><i class="bi bi-facebook"></i></button>
                         <button className="btn btn-outline-dark rounded-start-0"><i class="bi bi-twitter"></i></button>
                        <form className="d-flex m-3 justify-content-end">
                            
                            <input className="w-50 form-control  fw-bold serch rounded-start-pill " type="search" placeholder="جستجو کنید"></input>
                            <button className="btn btn-success rounded-start-0" type="submit"><i class="bi bi-search"></i></button>

                        </form>
                    </div>
                    <div className="bg-black w-100"><Typography className="text-white">برنامه نویس علی عباس توانا</Typography>
                    <Typography className="text-white">طراحی شده با <i className="bi bi-heart "></i></Typography></div>
                </div>
            </Container>
        </>
    )
}
export default footer;