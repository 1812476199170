import { createTheme } from '@mui/material/styles'
export const theme = createTheme({
    direction: 'rtl',
    palette:{
        primary:{
            main:'#00000'
        },
        secondary:{
            main:'#00000'
        },
        mode:'light'
    },
    typography:{
        fontFamily:'Yakanplas ,Roboto'
    }
  });