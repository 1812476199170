import Header from '../src/Components/Navbar';
import './App.css';
import MainLayout from './Mui/MainLayout';
import Slider from './Components/Slider';
import Navbar from './Components/footer'
// {Navbar is footer}
function App() {
  return (
    <>
      <MainLayout>
        <Header />
        <Slider/>
        <Navbar/>
      </MainLayout>
    </>
  );
}

export default App;
