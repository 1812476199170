import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

const Slider = () => {
  return (
    <>
      <Container fluid className='align-items-center justify-content-center d-flex w-75 mt-5 stick-slider' >
      <Carousel className='h-25'>
      <Carousel.Item>
      <img src={require('../../src/assets/9982bcc9-65ab-4a98-a79d-99a8d0119d37.webp')} alt='کیف و کفش آرمان' className='rounded-5 ' style={{width:'100%',height:'100%'}}/>
        <Carousel.Caption  className="d-xl-block  d-none"  >
          <h3>قل</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={require('../../src/assets/ed0f4352-df1a-4a23-a2c1-b008a65cdabe.webp')}alt='کیف و کفش آرمان' className='rounded-5' style={{width:'100%',height:'100%'}}/>
        <Carousel.Caption  className="d-xl-block  d-none" >
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={require('../../src/assets/pic-background.jpg')} alt='کیف و کفش آرمان' className='rounded-5' style={{width:'100%',height:'100%'}}/>
        <Carousel.Caption className="d-xl-block  d-none " >
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
      </Container>

    </>
  )
}
export default Slider;